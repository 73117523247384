import React from 'react'
import Technologies from '../data/expertise.json'
import { Row, Col, Badge } from 'antd';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import Animate from 'rc-animate';

const about = () => {
    return (
        <>
          <div className="site-card-border-less-wrapper content" style={{padding:'10px'}}>
            <div className="section-title text-center"> 
                <h1><Texty>About Us</Texty></h1>
                <hr />
            </div>
            <Animate transitionName="fade" transitionAppear>
            <Row>
                <Col xs={24} sm={12} lg={8}></Col>
                <Col xs={24} sm={12} lg={8} color="#555" style={{padding:'30px', backgroundColor:'#f1f1f3' }}>
                <h4 style={{marginLeft: '8px'}}>Some of our expertise are listed below.</h4>
                {Technologies.map((technology) => ( 
                    <Badge count={technology.text} style={{ backgroundColor: "#555", margin:'8px', color:'#00cde7', fontWeight:'bold' }}/>    
                ))}
                </Col>
                <Col xs={24} sm={12} lg={8}></Col>
                <Col xs={24} sm={12} lg={8} style={{height:'200px'}}></Col>
                <Col xs={24} sm={12} lg={8}></Col>
                <Col xs={24} sm={12} lg={8}></Col>
                <Col xs={24} sm={12} lg={8} style={{height:'174px'}}></Col>
                
          </Row>
           
          </Animate>
        
          </div>
        </>
    )
}

export default about
