import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
        <div className="flex-container-footer">
            <div className="item"><Link to="/privacy.html" target="_new">Privacy Policy</Link></div>
            {/* <div className="item"><Link to="/privacy.html" target="_new">Cookie Policy</Link></div> */}
            {/* <div className="item-spacer"></div> */}
            <div className="item-copy"> &copy; {(new Date()).getFullYear()} Shaqnologies. All rights reserved.</div>
        </div>
    )
}

export default Footer

