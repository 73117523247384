import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom'
import './App.css';

// import { Navbar, Footer } from './components'
import Home from './pages/home'
import Services from './pages/services/services'
import Industries from './pages/industries/industries'
import About from './pages/about';
import Navbarnew from './components/Navbarnew';
import Footer from './components/Footer';


function App() {
  const location = useLocation();
  
  return (
    <>
      { (location.pathname === "/") ? <></> : <Navbarnew /> }
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/services' element={<Services />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/about" element={<About />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
