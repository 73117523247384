import React from 'react'
import ServicesOffered from '../../data/services.json'
import { Card, Col, Row } from 'antd';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import Animate from 'rc-animate';
import Service from '../services/service'


const services = () => {
    // console.log(ServicesOffered)
    return (
        <>
        <div className="site-card-border-less-wrapper content" style={{padding:'10px' }} >
            <div className="section-title text-center"> 
                <h1><Texty>Services</Texty></h1>
                <hr />
            </div>
            
            <Animate transitionName="fade" transitionAppear>
            <Row gutter={[16, 16]}>
            {ServicesOffered.map((service) => ( 
                <Col xs={24} sm={12} lg={8} key={service.name}>
                    <Card hoverable bordered={false} style={{height:'170px', cursor:'default'}}>
                        <span className="page-icon">
                            <img src={Service(service.name)} alt="" width="30" height="30" key={service.name}/>
                            <b className="center-title">{service.name}</b>
                        </span>
                        <p style={{color:'#555'}}>{service.description}</p>
                    </Card>
                </Col>
            ))}
            {/* <Col xs={24} sm={12} lg={8} style={{height:'141px'}}></Col> */}
            </Row>
            </Animate>
            {/* <a href="#top"><UpCircleFilled style={{float:'right', fontSize:30, cursor:'pointer'}} /></a><br /> */}
        </div>
        </>
    )
}
export default services

