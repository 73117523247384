import Globe2 from '../../assets/svg/globe2.svg';
import Phone from '../../assets/svg/phone.svg';
import BoxSeam from '../../assets/svg/box-seam.svg';
import ClipBoardData from '../../assets/svg/clipboard-data.svg';
import Inspection from '../../assets/svg/inspection.svg';
import DataScience from '../../assets/svg/data-science-24.svg';
import MarketResearch from '../../assets/svg/market-research-analysis.svg';
import UiUx from '../../assets/svg/ux-design.svg';
import Cloud from '../../assets/svg/cloud.svg';
import HandBag from '../../assets/svg/handbag.svg';
import Mic from '../../assets/svg/mic.svg';


const getIcon = (service:string, src:string = "")  => {
    switch(service) {
        case "Web Development":
            src=Globe2
            break
        case "Mobile Development":
            src=Phone
            break
        case "Product Development":
            src=BoxSeam
            break
        case "Data Analytics":
            src=ClipBoardData
            break
        case "Data Mining":
            src=Inspection
            break
        case "Data Science":
            src=DataScience
            break
        case "Digital Competitors Intelligence":
            src=MarketResearch
            break
        case "UI/UX Design":
            src=UiUx
            break
        case "DevOps and Cloud":
            src=Cloud
            break
        case "Ecommerce Development":
            src=HandBag
            break
        case "Interview":
            src=Mic
                    }
    return src
}

const service = (service:string) => {
    return (
            getIcon(service, "")
    )
}

export default service
