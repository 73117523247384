import { Row, Col, Badge } from 'antd';
import headervideo from '../assets/videos/header-video.mp4'
import Navbarnew from '../components/Navbarnew'
import Badges from '../data/expertise.json'

// Data visualization, security, jwt, OAuth, Distributed Team, product development
const Navbar = () => {
    return (
        <>
        <div className="homepage-header-wrapper">
            <video autoPlay loop muted controls>
                <source src={headervideo} type="video/mp4" />
            </video>
            <Navbarnew />
            <div className="center">
                We're a software solutions and digital transformation consultancy with over 20 years of experience.
            </div>
        </div>
        <Row>
            <Col sm={24} color="#333" style={{padding:'30px', backgroundColor:'#f1f1f3' }}>
            <h4 style={{marginLeft: '8px'}}>Some of our expertise are listed below.</h4>
            {Badges.map((badge) => ( 
                <Badge count={badge.text} style={{ backgroundColor: "#777" , margin:'8px' }}/>    
            ))}
            </Col>
        </Row>
        </>
    )
}

export default Navbar
