import React from 'react'
import Cashcoin from '../../assets/svg/cash-coin.svg';
import GraphUp from '../../assets/svg/graph-up-arrow.svg';
import Truck from '../../assets/svg/truck.svg';
import Bell from '../../assets/svg/bell.svg';
import Cpu from '../../assets/svg/cpu.svg';
import Law from '../../assets/svg/law.svg';
import House from '../../assets/svg/house.svg';

import { Card, Col, Row } from 'antd';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import Animate from 'rc-animate';

const Industries = [
    {
        "name": "Finance",
        "description": "Financial services industry has seen some dispruting changes recently with millions of transactions every hour, financial institutions are looking towards digital banking solutions."
    },
    {
        "name": "Market Research",
        "description": "For competitive analysis of products and services more and more clients depened on market research companies, this is were we come in to help."
    },
    {
        "name": "Logistics",
        "description": "A complete solution for automation of supply chain management systems."
    },
    {
        "name": "Hospitality",
        "description": "We  provided best solutions to our hospitality industry clients by helping them with online bookings, mobile check-ins, search solutions, staff management, poperty management systems and so on."
    },
    {
        "name": "Information Technology",
        "description": "SaaS Development, API Development, Cloud and DevOps, E-commerce are some of our expertise that we helped information technology clients."
    },
    {
        "name": "Law Firms",
        "description": "With a slue of go-paperless solutions and tools, law firms are going hi-tech and paperless."
    },
    {
        "name": "Real Estate",
        "description": "We have digitized the whole processs of traditional real estate market to give users a reach experience in their journey for their dream place, a home."
    },
]

const getIcon = (industry:string, src:string = "")  => {
    switch(industry) {
        case "Finance":
            src=Cashcoin
            break
        case "Market Research":
            src=GraphUp
            break
        case "Logistics":
            src=Truck
            break
        case "Hospitality":
            src=Bell
            break
        case "Information Technology":
            src=Cpu
            break
        case "Law Firms":
            src=Law
            break
        case "Real Estate":
            src=House
            }
    return src
}


const industries = () => {
    return (
        <>
        <div className="site-card-border-less-wrapper content" style={{padding:'10px'}}>
            
            {/* <h1 style={{color:'#00cde7'}}>Industries</h1> */}
            <div className="section-title text-center"> 
                <h1><Texty>Industries</Texty></h1>
                <hr />
            </div>
            <Animate transitionName="fade" transitionAppear>
            <Row gutter={[16,16]}>
            {Industries.map((industry) => ( 
                <Col xs={24} sm={12} lg={8} key={industry.name}>
                    <Card hoverable bordered={false} style={{height:'170px'}}>
                        <p className="page-icon">  
                            <img src={getIcon(industry.name)} alt="" width="24" height="24" key={Cashcoin}/>
                            <b className="center-title">{industry.name}</b>
                        </p>
                        <p style={{color:'#555'}}>{industry.description}</p>
                    </Card>
                </Col>
            ))}
            <Col xs={24} sm={12} lg={8} style={{height:'308px'}}></Col>
            </Row>
            </Animate>
        </div>
        </>
    )
}

export default industries
