import React, {useState} from 'react'
import {Link} from 'react-router-dom'
// import {Button, Menu} from 'antd'
import { Menu, Drawer, Button, Form, Row, Col, Input } from 'antd';
import logo from '../assets/images/logo_turq.png'
import styled from 'styled-components'
import 'antd/dist/antd.css'
import './home.css'

import Animate from 'rc-animate';

// export const headervideo = "../images/header-video.mp4"

export const Navmenu = styled(Menu)`
    color: "#f1f1f3";
    // background: "#292d3e";
    background: transparent;
    z-index: 1000;
    top: 30px;
    // left: 24px;
    // position: absolute;
    float: "right";
    display: flex;
    justify-content: flex-end; 
`

export const Headerlink = styled(Link)`
    color: #00cde7;
    letter-spacing: 2px;
    transition: all 250ms ease-in;
    &:hover {
        color: #00DBF6;
        letter-spacing: 2px;
      }
`

const Navbarnew = () => {

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
        <div className="flex-container-outer dark">
            <div className="logo-div">
                <Link to="/"><img src={logo} alt="" className="logo-img" /></Link>
            </div>
            <Animate
          transitionName="fade"
          transitionAppear
        >
            <div className="flex-container">
                <div><Headerlink to="/services">Services</Headerlink></div>
                <div><Headerlink to="/industries">Industries</Headerlink></div>
                <div><Headerlink to="/about">About Us</Headerlink></div>
                <div><Button type="primary" shape="round" onClick={showDrawer}>Contact Us</Button></div>
            </div>
            </Animate>
            {/* <Button className="menu-conrol-container">
            </Button> */}
        </div>
        <Drawer title="Contact Us" placement="right" width={500} onClose={onClose} visible={visible}>
            <Form layout="vertical" hideRequiredMark>
                <Row gutter={[16,8]}>
                <Col span={24}>
                    <h1>Send us a message.</h1>
                    <Form.Item
                    name="name"
                    rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                    <Input placeholder="Please enter your name" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please enter your email' }]}
                    >
                    <Input placeholder="Please enter your email" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                    name="message"
                    rules={[
                        {
                        required: true,
                        message: 'Please enter your message',
                        },
                    ]}
                    >
                    <Input.TextArea rows={4} placeholder="Please enter your message" />
                    </Form.Item>
                </Col>
                </Row>
                <Row>
                    <Col span="24">
                        <Button type="primary" shape="round" >Contact Us</Button>
                    </Col>
                </Row>
            </Form>
        </Drawer>
        </>
    )
}

export default Navbarnew
